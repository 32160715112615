@import 'scss/mixin/media';

.hero :global {
  padding: 90px 0;

  height: calc(100vh - 300px);
  min-height: 400px;

  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 50px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: normal;

    text-align: center;

    color: #000;

    margin: 0 auto 30px auto;

    max-width: 620px;
  }

  p {
    font-size: 24px;
    line-height: 1.4;

    text-align: center;

    color: rgba(0, 0, 0, 0.6);

    margin-bottom: 40px;
  }
  @include media-md {
    h1 {
      font-size: 27px;
      line-height: 1.4;
      margin: 0 auto 20px;
    }
    p {
      font-size: 21px;
      margin-bottom: 30px;
    }
  }
  @include media-tn {
    height: auto;
    padding-top: 50px;
  }
}
