@import 'scss/mixin/media';

.footerform :global {
  padding: 170px 0 90px;
  @include media-sm {
    padding: 90px 0 70px;
  }
  background: url('../../../icons/grey-footer-bg.svg') top center no-repeat;
  background-size: cover;
  display: block;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .form-side {
      h3,
      p {
        text-align: left;
      }
      h3 {
        font-size: 36px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 500;
        line-height: 1.3;
      }
      p {
        margin: 30px 0 45px;
        font-size: 24px;
        line-height: 1.2;
        color: rgba(0, 0, 0, 0.4);
      }
      @include media-sm {
        h3 {
          font-size: 28px;
        }
        p {
          font-size: 21px;
        }
      }
      & > div {
        margin: 0;
      }
    }
  }
  img {
    margin-left: 20px;
    max-width: 100%;
  }
  @include media-md {
    img {
      display: none;
    }
    .container {
      justify-content: center;
      .form-side {
        h3,
        p {
          text-align: center;
        }
        & > div {
          margin: 0 auto;
        }
      }
    }
  }
}
