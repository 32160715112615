@import 'scss/mixin/media';

.faq :global {
  padding: 160px 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
  @include media-sm {
    padding: 70px 0 60px;
  }
  .section-title {
    margin-bottom: 70px;

    font-size: 40px;
    line-height: 1.3;

    text-align: center;

    color: rgba(0, 0, 0, 0.6);
    @include media-sm {
      margin-bottom: 40px;
      font-size: 24px;
      font-weight: 400;
    }
  }
  .faq-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 30px;
  }
}
.faq::after :global {
  position: absolute;
  z-index: -1;
  width: 150%;
  height: 90%;
  top: 0;
  left: -25%;
  margin: auto 0;
  transform: rotate(3deg);
  background-size: cover;
  background-image: linear-gradient(122deg, #fcfeff, #e5effd);
  content: '';
  background-position: center;
  bottom: 0;
  background-repeat: no-repeat;
}
