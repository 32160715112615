@import 'scss/mixin/media';

.comparerates :global {
  padding: 90px 0 105px;
  @include media-sm {
    padding: 50px 0 0;
  }
  .section-title {
    margin-bottom: 90px;

    font-size: 40px;
    font-weight: 400;
    line-height: 1.3;

    text-align: center;

    color: rgba(0, 0, 0, 0.6);
    @include media-md {
      margin-bottom: 40px;
      font-size: 24px;
    }
  }
  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .table {
      flex-shrink: 0;
      display: flex;
      flex-wrap: wrap;
      width: calc(50% - 20px);
      max-width: 570px;
      .table-item {
        width: 140px;
        height: 30px;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        a {
          cursor: pointer;
          background-image: linear-gradient(102deg, #0767be, #25b2c2);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: transparent;
          display: inline-block;
          padding: 5px 0;

          font-size: 14px;
          font-weight: 500;
          line-height: 1.2;
          letter-spacing: 0.5px;

          text-align: left;
          text-transform: uppercase;
        }
      }
    }
    .image {
      width: calc(50% - 20px);
      img {
        max-width: 100%;
      }
    }
    @include media-md {
      flex-direction: column;
      row-gap: 50px;
      .table,
      .image {
        width: 100%;
      }
    }
  }
}
