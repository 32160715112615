@import 'scss/basic';
@import 'scss/mixin/media';

.header {
  position: relative;
  display: block;
  height: auto;
  z-index: 20;
}
.header :global {
  height: 80px;
  position: relative;
  z-index: 3;

  @include media-sm {
    height: 60px;
  }

  .container,
  .container-large {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 90%;
  }

  .main-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: -25px;

    @include media-sm {
      display: none;
    }

    li {
      margin: 0 10px;
    }

    a {
      color: #0c73bf;
      padding: 15px;
      display: inline-block;
      position: relative;
      font-size: 16px;
      font-weight: 500;
      line-height: 1;

      &::after {
        content: '';
        position: absolute;
        display: block;
        bottom: 5px;
        left: 0;
        right: 0;
        margin: auto;
        width: 0;
        height: 3px;
        border-radius: 10px;
        background-color: #0c73bf;
        transition: width 0.3s;
      }

      &.active::after,
      &:focus::after,
      &:hover::after {
        width: calc(80% - 30px);
      }
    }
  }
  .mobile-menu-wrap {
    z-index: 99999;
    width: 40px;
    height: 40px;
    display: none;
    cursor: pointer;
    transition: opacity 0.2s linear;
    background: rgba(96, 125, 139, 0.72);
    border-radius: 4px;
    position: relative;

    @include media-sm {
      display: block;
    }

    span {
      display: block;
      height: 5px;
      width: 30px;
      border-radius: 40px;
      background-color: #fff;
      position: absolute;
      box-sizing: content-box;
      overflow: hidden;
      transition: all 0.4s ease;
      right: 5px;
      top: 0;
      &:nth-child(1) {
        margin-top: 7px;
        z-index: 9;
      }

      &:nth-child(2) {
        margin-top: 18px;
      }

      &:nth-child(3) {
        margin-top: 29px;
      }
    }
  }

  .mobile-menu-wrap.opened {
    background: transparent;

    .mobile-menu {
      opacity: 1;
      height: 400px;
      width: 400px;
    }

    span {
      background-color: #fff;
      border: none;
    }

    span:nth-child(1) {
      z-index: 100;
      transform-origin: 0 2.5px;
      transform: rotate(45deg);
    }

    span:nth-child(2) {
      overflow: visible;
      height: 400px;
      width: 400px;
      right: -160px;
      top: -160px;
      border-radius: 50%;
      background-color: #495464;
      box-shadow: 0 0 0 30px rgba(73, 84, 100, 0.4),
        0 0 0 65px rgba(73, 84, 100, 0.4);
      box-sizing: content-box;
    }

    span:nth-child(3) {
      z-index: 100;
      transform-origin: 0 2.5px;
      transform: rotate(-45deg);
    }

    li {
      margin-right: 148px;
    }

    &.dark {
      span {
        background-color: #fff;
      }

      span:nth-child(2) {
        background-color: rgba(75, 84, 100, 1);
        box-shadow: 0 0 0 30px rgba(73, 84, 100, 0.4),
          0 0 0 65px rgba(73, 84, 100, 0.4);
      }

      .mobile-menu li a {
        color: #fff;
      }
    }
  }

  .mobile-menu {
    transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    height: 0;
    width: 0;
    right: 0;
    top: 0;
    position: absolute;
    border-radius: 50%;
    z-index: 18;
    overflow: hidden;

    li {
      display: block;
      float: right;
      clear: both;
      height: 37px;
      margin-right: -160px;
      transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

      &:first-child {
        margin-top: 200px;
      }

      &:nth-child(1) {
        transition-delay: 0.05s;
      }

      &:nth-child(2) {
        transition-delay: 0.1s;
      }

      &:nth-child(3) {
        transition-delay: 0.15s;
      }

      &:nth-child(4) {
        transition-delay: 0.2s;
      }

      &:nth-child(5) {
        transition-delay: 0.25s;
      }

      a {
        color: #fff;
        font-size: 18px;
        width: 100%;
        padding: 0 20px;
        display: block;
        float: left;
        line-height: 30px;
        font-weight: 300;
      }
    }
  }
}
