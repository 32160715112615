@import 'scss/mixin/media';

.homepage :global {
  .main {
    .container {
      max-width: 1170px;

      display: block;

      width: 90%;
      margin: 0 auto;
      @include media-sm {
        width: calc(100% - 30px);
      }
    }
  }
}
