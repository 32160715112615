@import 'scss/mixin/media';
@import 'scss/basic';

.confirmation :global {
  position: relative;

  font-family: 'Merriweather';

  background: #f9f8f4;

  .popup-hide {
    display: none;
  }

  header {
    height: 80px;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      // gap: 15px;
      width: 100%;
      height: 100%;
      padding: 0 15px;

      img {
        height: 28px;
        fill: var(--theme-color);
      }
      // .contact-agent {
      //   display: grid;
      //   row-gap: 5px;
      // }
      span {
        display: none;

        text-align: right;

        font-weight: 700;
        font-size: 16px;
        line-height: 20.11px;
        color: #000000;

        &:first-of-type {
          font-weight: 400;
          font-size: 18px;
          line-height: 22.63px;
          color: #454545;

          margin: 30px 0 5px 0;
        }

        &:last-of-type {
          font-weight: 300;
          font-size: 12px;
          line-height: 15.08px;
          color: #6b6b6b;

          margin-top: 5px;
        }
      }
    }

    @include media-md {
      border: none;
      height: 65px;

      .container {
        justify-content: center;

        img {
          height: 20px;
        }
        span {
          display: none;
        }
      }
    }

    @media (max-width: 480px) {
      display: block;
    }
  }

  main {
    * {
      font-family: Merriweather;
    }

    min-height: calc(100vh - 80px - 40px);
    margin: 0px auto;
    justify-content: unset;
    h4 {
      &::first-letter {
        text-transform: uppercase;
      }

      width: 560px;
      font-size: 28px;
      line-height: 35.2px;
      margin: 24px auto 57px;
      @include media-md {
        font-size: 16px;
        line-height: 22.4px;
        width: 320px;
        margin: 30px auto;
      }
    }
    .content {
      margin: 0px auto 40px auto;
      width: 830px;

      display: grid;
      row-gap: 20px;

      .not-found {
        font-size: 20px;
        margin-top: -80px;
      }
      @include media-md {
        width: 340px;
        row-gap: 10px;
        .not-found {
          margin-top: 0px;
          font-size: 16px;
        }
      }
    }
    #show {
      opacity: 1;
    }
    #hide {
      opacity: 0;
    }
    .progress-bar {
      height: 20px;
      margin: 50px auto 0px auto;
      width: 780px;
      border-radius: 10px;
      .info {
        display: none;
      }
      .line {
        margin: 0px;
        border-radius: 10px;
        height: 20px;
        .line-inner {
          border-radius: 10px;
        }
      }
      @include media-md {
        margin: 0px auto;
        height: 10px;
        width: 320px;
        .line {
          height: 10px;
        }
      }
    }
  }
}
