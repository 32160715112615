@import 'scss/mixin/media';
@import 'scss/basic';
.loading,
.loaded {
  display: flex;
  align-items: center;
  height: 95px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #989898;
  @include media-md {
    width: 340px;
    height: 60px;
  }
}
.loading {
  svg {
    &:first-of-type {
      width: 43px;
      height: 43px;
      margin: 0px 30px;
    }
    &:last-of-type {
      width: 39px;
      height: 39px;
      margin: 0px 30px;
      animation: spinner 3s infinite linear;
    }
    @include media-md {
      &:first-of-type,
      &:last-of-type {
        width: 24px;
        height: 24px;
        margin: 0px 15px;
      }
    }
  }
}

.loading-placeholder {
  flex-grow: 1;
}

.text-block {
  div {
    height: 10px !important;
  }
  .text-row:first-of-type {
    width: 350px !important;
  }
  .text-row:last-of-type {
    width: 500px !important;
  }
  @include media-md {
    div {
      height: 8px !important;
    }
    .text-row:first-of-type {
      width: 98px !important;
    }
    .text-row:last-of-type {
      width: 169px !important;
    }
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loaded {
  cursor: pointer;
  img {
    width: 120px;
    height: 60px;
    margin: 0 30px 0 20px;
    border: none;
  }
  .description {
    max-width: 440px;
    max-height: 50px;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;

    color: #000;
  }
  .view-my-quote {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 180px;
    height: 60px;
    border-radius: 5px;
    border: none;

    background-color: var(--theme-color);
    color: #fff;

    font-weight: 900;
    font-size: 16px;
    line-height: 20px;

    margin: 0px 20px 0 auto;
    a {
      display: flex;
      align-items: center;
    }
    svg {
      margin-left: 10px;
    }
    .view-quote {
      text-align: center;
      color: #fff;
    }
    margin-left: auto !important;
  }
  @include media-md {
    position: relative;
    margin: 0px;
    display: block;
    height: 110px;
    img {
      position: absolute;
      width: 80px;
      height: 40px;
      left: 10px;
      top: 15px;
      margin: 0px;
    }
    .description {
      position: absolute;
      font-size: 13px;
      line-height: 16.34px;
      width: 230px;
      height: 42px;
      top: 15px;
      left: 100px;
    }
    .view-my-quote {
      position: absolute;
      width: 340px;
      height: 40px;
      margin: 0px;
      top: 70px;
      left: -1px;
      border-radius: 0;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;

      font-size: 12px;
      line-height: 15px;

      svg {
        margin-left: 5px;
      }
    }
  }
}
