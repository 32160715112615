button,
[role='button'],
[type='button'] {
  cursor: pointer;
}
.btn {
  font-family: 'Roboto', sans-serif;
  display: block;
  width: 320px;
  max-width: 100%;
  height: 70px;
  padding: 10px 20px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  border-radius: 4px;

  font-size: 24px;
  line-height: 28px;

  text-align: center;
  font-weight: bold;
  letter-spacing: -0.5px;

  transition: all 0.3s;

  &:hover {
    box-shadow: none;
  }

  &.with-arrow {
    position: relative;
    svg {
      position: absolute;
      right: 14px;
      top: calc(50% - 8px);
    }
  }
  &.selected {
    color: var(--theme-color) !important;
  }
  &:disabled {
    opacity: 0.8;
  }

  @include media-md {
    height: 50px;
    font-size: 18px;
    line-height: 21px;
  }
}

.btn-blue {
  background: var(--theme-color);
  border: 1px solid var(--theme-color);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px var(--theme-color), 0px 8px 16px -9px var(--theme-color),
    inset 3px 4px 18px var(--theme-color);
  border-radius: 4px;
  color: white;
}
.btn-outline-blue {
  background: #ffffff;
  border: 1px solid var(--theme-color);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.21);
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: black;
  &:hover {
    border-color: var(--theme-color);
    box-shadow: 5px 7px 9px -2px rgba(0, 0, 0, 0.21);
  }
  @include media-md {
    height: 50px;
    font-size: 14px;
    line-height: 16px;
  }
}

.btn-white {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.21);

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4b4b4b;

  &.with-arrow {
    svg rect {
      fill: #979797;
    }
  }
}
