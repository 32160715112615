@import 'mixin/media';

.progress-bar {
  @include media-tn {
  }

  .block-progress {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .line {
    height: 8px;
    background: rgba(168, 205, 249, 0.24);

    &-inner {
      content: '';
      display: block;
      width: 3%;
      height: 100%;
      background: var(--theme-color);
      transition: all 0.5s ease;
    }
    @media (max-width: 480px) {
      border-radius: 8px;
      height: 10px;
      margin: 9px 20px 4px;
      overflow: hidden;
    }
  }

  .info {
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #979797;
    text-align: center;
    text-transform: uppercase;
    margin-top: 8px;
    @include media-md {
      font-size: 11px;
      line-height: 26px;
      margin-top: 0;
    }
  }

  strong {
    font-weight: bold;
  }

  @media (max-width: 480px) {
    height: 50px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
  }
}
