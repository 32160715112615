.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-flex {
  display: flex;
  align-items: center;
}
@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@mixin hover-effect {
  &:hover {
    border-color: blue;
    box-shadow: 5px 7px 9px -2px rgba(0, 0, 0, 0.21);
  }
}
@mixin mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-auto {
  @include mx-auto;
}
.ml-auto {
  margin-left: auto !important;
}
