@import 'scss/mixin/media';

.popup :global {
  position: absolute;
  z-index: 99999;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  .background {
    position: absolute;
    z-index: 1;
    
    width: 100%;
    height: 100%;

    background-color: #f9f8f4e5;
  }

  @include media-md {
    align-items: flex-start;
  }
  .content {
    position: relative;
    z-index: 2;

    padding: 40px 20px 0 20px;

    width: 600px;
    height: 400px;

    border-radius: 5px;

    background-color: #fff;

    box-shadow: 0 0 50px 15px #00000026;

    @include media-sm {
      margin-top: 67px;
      padding: 30px 16px 0 16px;

      width: 340px;
      height: 309px;

      box-shadow: 0 0 50px 15px #00000026;
    }

    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;

      color: #000;

      @include media-sm {
        font-size: 15px;
        line-height: 22.5px;
      }
    }
    button {
      position: relative;
      margin-top: 25px;

      width: 440px;
      height: 70px;

      border-radius: 5px;
      border: none;

      background-color: var(--theme-color);

      font-family: 'Merriweather';
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      color: #fff;

      @include media-sm {
        width: 308px;
        height: 60px;

        font-size: 16px;
        line-height: 20px;
      }
      svg {
        position: absolute;
        top: 23px;
        right: 25px;

        width: 24px;
        height: 24px;

        @include media-sm {
          width: 16px;
          height: 16px;

          top: 22px;
          right: 8px;
        }
      }
    }
  }
}
