@import 'scss/mixin/media';

.faqitem :global {
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

  width: calc(50% - 15px);
  max-width: 570px;
  @include media-md {
    width: 100%;
  }

  .faq-title {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);

    min-height: 60px;
    padding: 18px 10px;

    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;

    text-align: left;
    .faq-icon {
      position: relative;
      height: 22px;
      width: 22px;
      flex-shrink: 0;
      margin-left: 6px;
      user-select: none;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 4px;
        height: 100%;
        margin-left: -2px;
        background-image: linear-gradient(135deg, #0767be, #25b2c2);
        transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 4px;
        margin-top: -2px;
        background-image: linear-gradient(135deg, #0767be, #25b2c2);
        transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
      }
    }
    @include media-md {
      font-size: 18px;
    }
  }
  .faq-content {
    display: none;
  }
}
.faqitem:global.active {
  .faq-icon::before {
    transform: rotate(90deg);
  }
  .faq-icon::after {
    transform: rotate(180deg);
  }
  .faq-content {
    display: block;

    padding: 0 10px 15px;
    p {
      color: rgba(0, 0, 0, 0.4);
      font-size: 16px;
      line-height: 1.63;
      text-align: left;
    }
  }
}
