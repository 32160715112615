@import 'scss/basic';
@import 'scss/mixin/media';
.footer :global {
  background-color: #4b5a6b;

  a,
  div,
  h4 {
    text-align: left;
    @include media-tn {
      text-align: center;
    }
  }

  .logo {
    color: #fff;

    font-size: 22px;
    line-height: 1.2;
    font-weight: 700;

    @include media-lg {
      display: none;
    }
  }

  .footer-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 60px 0 40px;

    @include media-md {
      padding-bottom: 0;
    }
  }

  .copyright {
    border-top: 1px solid #979797;
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    padding: 40px 0;
    color: rgba(255, 255, 255, 0.5);

    @include media-md {
      padding: 30px 0;
    }
  }

  .footer-menu-wrap {
    display: flex;
    // padding-top: 15px;
    align-items: flex-start;

    @include media-lg {
      width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }

  .footer-menu {
    display: flex;
    flex-direction: column;
    margin: 0 25px;

    @include media-md {
      margin: 0 0 50px;
      width: 45%;
    }

    @include media-tn {
      width: 100%;
      text-align: center;
    }

    h4 {
      padding: 0 10px;
      font-size: 16px;
      line-height: 1.2;
      font-weight: bold;
      letter-spacing: 2px;
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    a {
      font-size: 16px;
      line-height: 1.2;
      padding: 8px 10px;
      display: inline-block;
      color: rgba(255, 255, 255, 0.6);
      transition: all 0.3s;

      &:hover {
        color: #25b2c2;
      }
    }
  }

  .legal-note {
    font-size: 14px;
    line-height: 1.33;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    padding-bottom: 34px;

    a {
      color: rgba(255, 255, 255, 0.7);
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

      @include media-sm {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }

    p:first-child {
      margin-bottom: 34px;
      padding: 0 15px;

      @include media-sm {
        padding: 0;
      }
    }

    p + p {
      margin-top: 17px;
      //word-break: break-all;
    }
  }
}
