@import 'scss/mixin/media';

.plan :global {
  background-image: url('../../../icons/wave-background.svg');
  background-size: 1600px auto;
  background-position: center 100px;
  background-repeat: no-repeat;

  padding: 0 0 190px 0;

  @include media-sm {
    background-size: cover;
    padding-bottom: 100px;
  }

  overflow-x: hidden;

  .tooltips {
    width: 1170px;
    height: 230px;
    margin: 0 auto 120px;
  }

  .section-title {
    color: #fff;
    margin-bottom: 46px;
    font-size: 40px;
    line-height: 1.3;
    text-align: center;
    @include media-sm {
      font-size: 24px;
      font-weight: 400;
    }
  }

  .right-plan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media-sm {
      flex-direction: column;
      justify-content: space-around;
    }
  }

  .right-plan-text {
    width: 40%;
    max-width: 470px;
    .item {
      margin-bottom: 60px;
      h3,
      p {
        text-align: left;
      }
      h3 {
        margin-bottom: 20px;

        font-size: 24px;
        line-height: 1.2;
        font-weight: 900;
        letter-spacing: 1.5px;

        color: #fff;

        text-transform: uppercase;
      }
      p {
        font-size: 18px;
        line-height: 1.44;
        letter-spacing: 0.5px;
        color: #fff;
      }
      @include media-sm {
        h3 {
          font-size: 18px;
        }
        p {
          font-size: 16px;
        }
      }
    }
    @include media-sm {
      width: 100%;
    }
  }
  .right-plan-quotes {
    width: 55%;
    max-width: 600px;
    .title {
      display: flex;
      align-items: center;

      margin-bottom: 15px;

      font-size: 12px;
      line-height: 1.2;
      font-weight: 500;
      letter-spacing: 1px;

      color: #dedede;

      text-transform: uppercase;
      text-align: center;
    }
    .title::before,
    .title::after {
      margin-right: 20px;
      content: '';
      display: block;
      height: 1px;
      background-color: #dedede;
      -webkit-box-flex: 1;
      flex-grow: 1;
    }
    .title::after {
      margin-right: 0;
      margin-left: 20px;
    }
    img {
      margin-bottom: 20px;
      max-width: 100%;
    }
    @include media-sm {
      width: 100%;
      max-width: inherit;
    }
  }
}
