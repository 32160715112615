@import 'scss/mixin/media';
@import 'scss/basic';

.personalizedQuote :global {
  font-family: 'Merriweather';

  background: #f9f8f4;

  header {
    height: 80px;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      width: 100%;
      height: 100%;
      padding: 0 15px;

      img {
        height: 28px;
        fill: var(--theme-color);
      }
      .contact-agent {
        display: grid;
        row-gap: 5px;
      }
      span {

        text-align: right;

        font-weight: 700;
        font-size: 16px;
        line-height: 20.11px;
        color: #000000;

        &:first-of-type {
          font-weight: 400;
          font-size: 18px;
          line-height: 22.63px;
          color: #454545;

          margin: 30px 0 5px 0;
        }

        &:last-of-type {
          font-weight: 300;
          font-size: 12px;
          line-height: 15.08px;
          color: #6b6b6b;

          margin-top: 5px;
        }
      }
    }

    @include media-md {
      border: none;
      height: 65px;

      .container {
        justify-content: center;

        img {
          height: 20px;
        }
        span {
          display: none;
        }
      }
    }

    @media (max-width: 480px) {
      display: block;
    }
  }

  main {
    min-height: calc(100vh - 80px - 40px);
    justify-content: unset;

    .form-step {
      padding-top: 120px;

      @include media-md {
        padding-top: 23px;
      }
    }

    .control-new {
      width: 400px;
    }
    .control-new input,
    .control-new select,
    .control-new textarea {
      border-radius: 5px;

      font-family: 'Merriweather';
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      text-align: left;

      height: 60px;

      padding: 17px 20px;
      box-shadow: none;
    }

    .control-new.with-label {
      input {
        padding-top: 27px;
      }

      select {
        padding-top: 22px;
        padding-bottom: 10px;
      }
    }

    .checkcontainer {
      margin-top: 10px;

      background: #ffffff;
      border: 1px solid #c7c7c7;
      border-radius: 5px;

      font-family: 'Merriweather';
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;

      width: 400px;
      margin-left: 200px;
      @include media-md {
        width: 100%;
        margin-left: 0px;
      }

      padding: 17px 20px 17px 45px;

      .radiobtn {
        top: 22px;
        left: 20px;

        border: 1px solid #bebebe;
      }
    }

    .btn {
      font-family: 'Merriweather';
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;

      border-radius: 5px;

      box-shadow: none;

      height: 60px;
      width: 260px;
    }

    .btn.with-arrow {
      svg {
        display: none;
      }
    }

    h4 {
      width: max-content;
      font-weight: 700;
      font-size: 28px;
      line-height: 35px;

      margin-left: auto;
      margin-right: auto;

      @include media-md {
        font-size: 20px;
        line-height: 140%;
        width: inherit;
      }
    }

    h5 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      color: #6b6b6b;

      @include media-md {
        font-size: 12px;
        line-height: 15px;
      }
    }

    .content {
      // width: 400px;
      .checked {
        background-color: var(--theme-color);
        color: #fff;
      }
      .unchecked {
        .radiobtn-around {
          border: 1px solid #c7c7c7;
        }
        .radiobtn {
          display: none;
        }
      }
      @include media-md {
        width: 100%;
        max-width: 320px;
      }
    }
  }

  .step-zipcode {
    h5 {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .content {
      margin-top: 30px;

      display: flex;
      // align-items: center;
      justify-content: center;

      .control-new {
        width: 220px;
        margin: 0;

        input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        label,
        input {
          padding-left: 45px;
        }
      }
      .btn-blue {
        margin: 0;
        width: 220px;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    @include media-md {
      .box {
        margin-left: 0;
        margin-right: 0;
      }
      h5 {
        gap: 5px;
      }
      .content {
        display: block;

        .control-new {
          margin-left: auto;
          margin-right: auto;
          width: unset;

          input {
            border-radius: 5px;
          }
        }

        .btn-blue {
          margin-top: 20px;
          width: 100%;

          border-radius: 5px;
        }
      }
    }
  }

  .step-insurance-type {
    h4 {
      margin-bottom: 40px;
    }
  }

  .step-credit {
    h4 {
      margin-bottom: 40px;
    }
  }

  .step-insurance-claim {
    h4 {
      margin-bottom: 40px;
      // display: none !important;
    }
  }

  .step-new-address {
    width: 100%;

    h4 {
      &::first-letter {
        text-transform: uppercase;
      }
    }
    .content {
      max-width: 560px;
      margin: 40px auto 0 auto;

      .non-detail {
        text-decoration: underline;

        font-size: 20px;
        line-height: 25px;

        width: 200px;

        color: #000;

        cursor: pointer;
      }

      .options {
        .zipcode-svg {
          display: none;
        }
        .control-new {
          width: 400px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        row-gap: 10px;
        column-gap: 10px;

        .detailedAddress {
          display: grid;
          grid-template-columns: repeat(11, minmax(0, 1fr));
          row-gap: 10px;
          column-gap: 10px;
          .control-new#city {
            grid-column-start: 1;
            grid-column-end: 4;
          }
          .control-new#state {
            grid-column-start: 4;
            grid-column-end: 9;
          }
          .control-new#zipcode {
            grid-column-start: 9;
            grid-column-end: 12;
          }
          @include media-md {
            .control-new#city, .control-new#state, .control-new#zipcode {
              grid-column-start: 1;
              grid-column-end: 12;
            }
          }
        }

      }
    }
    .tcp-text {
      display: block;

      max-width: 600px;

      font-family: Roboto;
      font-size: 12px;
      line-height: 160%;
      font-style: italic;

      text-align: left;

      color: #979797;

      border-top: 1px solid #eaeaea;

      margin-top: 20px;
      padding-top: 15px;
      padding-bottom: 20px;

      a {
        color: #979797;
        text-decoration: underline;
      }
    }

    .btn-blue {
      margin: 40px auto 0;
      // width: 100%;
    }

    @include media-md {
      .btn-blue {
        height: 60px;
      }
    }
  }

  .step-email {
    h4 {
      margin-bottom: 10px;
    }

    .content {
      margin-top: 40px;

      .btn-blue {
        width: 260px;
        margin-top: 30px;
      }
    }

    @include media-md {
      .content {
        .btn-blue {
          margin-top: 20px;
          width: 100%;
        }
      }
    }
  }

  .step-gender {
    h4 {
      margin-bottom: 40px;
    }

    .content {
      display: block;
    }
  }

  .step-birthday {
    .content {
      .btn-blue {
        margin-top: 30px;
      }
    }
  }

  .step-name {
    h4 {
      margin-bottom: 40px;
    }
    .content {
      max-width: 400px;
      .options {
        display: flex;
        flex-direction: column;

        gap: 10px;

        .control-new {
          width: unset;
          margin: 0;
          input {
            text-align: left;
          }
        }
      }

      .btn-blue {
        margin-top: 20px;
      }
    }

    @include media-md {
      h4 {
        margin-bottom: 25px;
      }
    }
  }

  .step-phone {
    :root {
      --address-form-gap: 20px;
    }
    h4 {
      &::first-letter {
        text-transform: uppercase;
      }
      margin-bottom: 10px;
    }

    h5 {
      margin-top: 10px;
      font-size: 14px !important;
      line-height: 18px !important;
      @include media-md {
        font-size: 10px !important;
        line-height: 13px !important;
      }
    }

    .content {
      margin-top: 40px;

      .btn-blue {
        margin-top: 30px;
        width: 440px;
      }
    }

    @include media-md {
      .content {
        margin-top: 30px;

        .btn-blue {
          margin-top: 20px;
          height: 60px;
          width: 100%;
        }
      }
    }
  }
}
