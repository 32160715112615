@import 'scss/mixin/media';

.control-new input,
.control-new select,
.control-new textarea {
  border: 1px solid #c7c7c7;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px -2px rgba(65, 65, 65, 0.21);
  border-radius: 4px;
  font-weight: 500;
  font-size: 24px;
  padding: 10px 15px;
  height: 70px;
  letter-spacing: -1px;
  text-align: center;
  @include media-md {
    font-size: 20px;
    height: 50px;
  }
}
.control-new {
  position: relative;
  width: 320px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  .validation-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 17px;
    right: 15px;
    pointer-events: none;
  }
  // label {
  //   color: #6b6b6b;
  // }

  .zipcode-svg {
    position: absolute;
    padding: 15px;
  }

  .error-text {
    position: absolute;
    font-weight: 700;
    font-size: 14px;
    line-height: 17.6px;
    top: 65px;
    left: 20px;
    color: #f00;
    display: none;
  }

  &:focus-within {
    input,
    select,
    textarea {
      border: 2px solid var(--theme-color);
    }
    label {
      color: var(--theme-color);
    }
  }
  &.has-error {
    height: 80px;
    input,
    select {
      // background-color: rgba(255, 0, 57, 0.05);
      // border-color: rgba(255, 0, 57, 0.3);
      border: 2px solid #f00;
    }
    .error-text {
      display: block;
    }
  }

  &.has-warning {
    .validation-icon {
      fill: #c08319;
    }
  }

  &.no-validate {
    .validation-icon {
      display: none !important;
    }
    input,
    select {
      border-color: rgba(73, 84, 100, 0.65);
    }
  }
}

.control-new.with-label {
  position: relative;
  // height: auto;
  label {
    position: absolute;
  }
  textarea {
    resize: vertical;
  }
  input,
  textarea,
  select {
    font-size: 20px;
    line-height: 23px;
    padding: 10px 13px;
    height: 70px;
    text-align: left;
  }
  .option-wrapper {
    max-height: 220px;
    overflow: scroll;
    &:active {
      display: block;
    }
    display: none;
    position: absolute;
    width: 100%;
    z-index: 1;
    border: 2px solid var(--theme-color);
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: white;
    .option {
      width: 100%;
      height: 50px;

      padding: 15px;

      display: flex;
      align-items: center;
      color: #000;
      cursor: pointer;
      &:hover,
      &:first-child {
        color: var(--theme-color);
        background: rgba(36, 126, 228, 0.12);
      }
    }
    .option-info {
      padding: 15px;
    }
  }
  &:focus-within {
    .option-wrapper {
      display: block;
    }
  }

  @include media-md {
    input {
      height: 50px;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.has-no-content {
  label {
    font-size: 20px;
    line-height: 25.14px;
    transition: 0.2s;
    padding: 17px 20px 0px 20px;
  }
  &:focus-within {
    label {
      font-size: 10px;
      line-height: 13px;
      transition: 0.2s;
      padding: 6px 20px 0px 20px;
    }
  }
}
.has-content {
  label {
    font-size: 10px;
    line-height: 13px;
    transition: 0.2s;
    padding: 6px 20px 0px 20px;
  }
}
