@import 'scss/mixin/media';

.manual :global {
  padding: 50px 0 100px;
  img,
  svg {
    max-width: 100%;
  }
  .image {
    text-align: center;
    margin-bottom: 50px;
    @include media-sm {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .section-title {
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 1.3;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    @include media-sm {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 15px;
    }
  }
  .description {
    font-size: 22px;
    line-height: 1.36;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 55px;
    @include media-sm {
      font-size: 18px;
    }
  }
  .image-big {
    text-align: center;
    margin-bottom: 25px;
  }
  .three-cols {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 35px;
    @include media-md {
      justify-content: space-around;
    }
    .item {
      width: 31%;
      @include media-md {
        width: 42%;
      }
      @include media-sm {
        width: 100%;
      }
      max-width: 360px;
      margin-bottom: 30px;
      .number {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-image: linear-gradient(to bottom, #0767be, #25b2c2);
        margin-bottom: 16px;
        position: relative;
        overflow: hidden;
        @include media-sm {
          margin-left: auto;
          margin-right: auto;
        }
        span {
          background-image: linear-gradient(102deg, #0767be, #25b2c2);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: transparent;
          position: absolute;
          width: 17px;
          height: 31px;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 1;
          font-size: 30px;
          font-weight: 500;
        }
      }
      .number::after {
        content: '';
        display: block;
        position: absolute;
        background: #fff;
        height: calc(100% - 6px);
        width: calc(100% - 6px);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 50%;
      }
      h4 {
        font-size: 22px;
        line-height: 1.2;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 15px;
      }
      p {
        font-size: 18px;
        line-height: 1.56;
        color: rgba(0, 0, 0, 0.5);
      }
      h4,
      p {
        text-align: left;
      }
      @include media-sm {
        h4,
        p {
          text-align: center;
        }
        h4 {
          font-size: 20px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .btn {
    display: block;
    width: 280px;
    margin: 0 auto;
    border: none;
    text-align: center;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;
    background: #00f;
  }
  .btn-blue-big {
    height: 70px;
    font-size: 22px;
    line-height: 28px;
    padding: 21px 20px;
    border-radius: 4px;
    background-color: #0c73bf;
    letter-spacing: 2px;
  }
}
