.text-desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4b4b4b;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-600 {
  font-weight: 600 !important;
}
.text-bold {
  font-weight: bold !important;
}