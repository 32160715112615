@import 'scss/mixin/media';

.zipcode :global {
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 370px;

  border-radius: 4px;

  background-image: linear-gradient(101deg, #0767be, #25b2c2);

  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);

  position: relative;

  .control {
    position: relative;

    height: 66px;
    width: calc(50% - 2px);
    margin-left: 2px;
    // max-width: 265px;

    display: flex;

    input {
      width: 100%;
      height: 100%;
      appearance: none;

      border-color: transparent;
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid #ccc;

      padding: 20px 28px;

      background-color: #fff;

      color: rgba(0, 0, 0, 0.4);

      font-size: 22px;
      line-height: 1.3;
    }
  }
  .btn {
    height: 70px;
    width: calc(50% - 2px);
    position: relative;
    // max-width: 265px;

    color: #fff;

    background: transparent;

    padding: 10px;

    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;

    letter-spacing: 1px;
    text-transform: none;

    transition: 0.3s;

    cursor: pointer;

    .flex-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      fill: #fff;

      margin-left: 10px;
      margin-right: -6px;

      transition: 0.2s;
    }
    & :hover {
      svg {
        transform: translate3d(-2px, 0, 0) scale(0.9);
        transition: 0.2s;
      }
    }
  }
  @include media-tn {
    flex-direction: column;
    background-image: none;
    box-shadow: none;
    .control {
      width: 265px;
      margin-bottom: 20px;
      input {
        border-color: #cdd6df;
        border-radius: 4px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
      }
    }
    .btn {
      height: 70px;
      background-image: linear-gradient(101deg, #0767be, #25b2c2);
      border-radius: 4px;
      width: 265px;
    }
  }
}
