.checkcontainer {
  .info-label {
    padding-left: 8px;
  }
  display: block;
  position: relative;
  padding-left: 20px;
  margin-right: 14px;
  cursor: pointer;
  user-select: none;
  color: #161717;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: left;

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .radiobtn-around {
    position: absolute;
    margin-top: 0px;
    left: 15px;
    top: 17px;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
  }
  .radiobtn {
    position: absolute;
    margin-top: 0px;
    top: 2.5px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid var(--theme-color);
  }
}

.checkcontainer input:checked ~ .radiobtn {
  background-color: var(--theme-color);
}

.checkcontainer .radiobtn:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.checkcontainer input:checked ~ .radiobtn:after {
  display: block;
}
