@import 'scss/mixin/media';

.insurancetype :global {
  padding: 190px 0;
  margin-top: -150px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  .section-title {
    margin-bottom: 90px;
    font-size: 40px;
    line-height: 1.3;
    text-align: center;
    color: #000;
    @include media-sm {
      font-size: 24px;
      font-weight: 400;
    }
  }
  .insurance-types {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .insurance-types-item {
      padding: 20px 35px;
      margin-bottom: 35px;
      width: 32%;
      max-width: 370px;
      background-color: #fff;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.17);
      @include media-md {
        width: 46%;
      }
      @include media-sm {
        width: 100%;
      }
      .image {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        height: 138px;
        margin-bottom: 26px;
        img {
          max-width: 100%;
        }
      }
      h4 {
        margin-bottom: 10px;

        font-size: 20px;
        line-height: 1.2;
        font-weight: 700;
        letter-spacing: 0.6px;

        text-transform: uppercase;
        text-align: center;

        color: rgba(0, 0, 0, 0.57);
      }
      p {
        font-size: 18px;
        line-height: 1.2;
        color: rgba(0, 0, 0, 0.4);
        margin-bottom: 5px;
        text-align: left;
      }
      @include media-sm {
        h4 {
          font-size: 17px;
        }
        p {
          font-size: 16px;
        }
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;

        width: max-content;

        margin: 0 auto;
        padding: 10px 20px;

        color: rgba(0, 0, 0, 0.9);
        cursor: pointer;

        span {
          background-image: linear-gradient(102deg, #0767be, #25b2c2);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: transparent;
          font-size: 16px;
          line-height: 1.2;
          font-weight: 600;
          letter-spacing: 0.4px;
          text-transform: uppercase;
        }
        svg {
          fill: #24aec1;
          margin-left: 8px;
          transition: all 0.2s;
        }
      }
    }
    @include media-sm {
      justify-content: space-around;
    }
  }
  & ::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 150%;
    height: 90%;
    top: 0;
    bottom: 0;
    left: -25%;
    margin: auto 0;
    background-size: cover;
    background-position: center;
    transform: rotate(3deg);
    background-image: linear-gradient(122deg, #fcfeff, #e5effd);
    background-repeat: no-repeat;
  }
}
